<!--
 * @Description: 服务快捷填充
 * @Author: ChenXueLin
 * @Date: 2021-10-20 15:23:15
 * @LastEditTime: 2022-07-15 16:39:49
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="firstServerCode">
              <e6-vr-select
                v-model="searchForm.firstServerCode"
                :data="firstTypeList"
                :props="{
                  id: 'codeId',
                  label: 'codeName'
                }"
                placeholder="一级服务类型"
                title="一级服务类型"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondServerCode">
              <e6-vr-select
                v-model="searchForm.secondServerCode"
                :data="secondTypeList"
                :props="{
                  id: 'codeId',
                  label: 'codeName'
                }"
                placeholder="二级服务类型"
                title="二级服务类型"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="createdBy">
              <el-input
                v-model="searchForm.createdBy"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 添加/编辑填充信息 -->
    <el-dialog
      v-dialogDrag
      :title="dialogTitle"
      :visible.sync="addDioalog"
      width="500px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="addDialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-dialog"
    >
      <el-form
        ref="addFillForm"
        label-width="120px"
        :model="addFillForm"
        :rules="addFillFormRules"
      >
        <el-form-item label="一级服务类型" prop="firstServerCode">
          <e6-vr-select
            v-model="addFillForm.firstServerCode"
            :data="firstTypeList"
            placeholder="一级服务类型"
            title="一级服务类型"
            clearable
            :props="{
              id: 'codeId',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="二级服务类型名称" prop="secondServerCode">
          <e6-vr-select
            v-model="addFillForm.secondServerCode"
            :data="dialogSecondTypeList"
            placeholder="二级服务类型名称"
            title="二级服务类型名称"
            clearable
            :props="{
              id: 'codeId',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="自动填充内容" prop="fillContent">
          <el-input
            placeholder="自动填充内容"
            v-model="addFillForm.fillContent"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getFillList,
  getFirstType,
  getFillDetail,
  getSecondType,
  deleteFill,
  createFill,
  updateFill
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "quickFill",
  components: {},
  mixins: [listPage, listPageReszie, base],
  data() {
    return {
      loading: false,
      total: 0,
      selColumnId: [], //勾选数据
      firstTypeList: [], //一级服务类型下拉框数据
      secondTypeList: [], //二级服务类型下拉框数据
      searchForm: {
        firstServerCode: "", //一级服务类型
        secondServerCode: "", //二级服务类型
        fillContent: "", //自动填充内容
        createdBy: "", //创建人
        createTime: [], //创建时间
        beginTimeTimestamp: "", //服务开始时间
        endTimeTimestamp: "", //服务结束时间
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "firstServer",
          display: true,
          fieldLabel: "一级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondServer",
          display: true,
          fieldLabel: "二级服务类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fillContent",
          display: true,
          fieldLabel: "服务详情自动填充内容",
          width: 250,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTimeString",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      detailInfo: {}, //详情信息
      /*****新增/编辑*******/

      dialogSecondTypeList: [], //弹框一级服务类型
      dialogType: 1, //1新增2修改
      addDialogLoading: false,
      addDioalog: false,
      addFillForm: {
        fillContent: "", //自动填充内容
        firstServerCode: "", //一级服务类型
        secondServerCode: "" //二级服务类型
      },
      addFillFormRules: {
        firstServerCode: [
          {
            required: true,
            message: "请选择一级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        secondServerCode: [
          {
            required: true,
            message: "请选择二级服务类型",
            trigger: ["blur", "change"]
          }
        ],
        fillContent: [
          {
            required: true,
            message: "请输入自动填充内容",
            trigger: ["blur", "change"]
          }
        ]
      },
      refTable: "tableList",
      queryListAPI: getFillList
    };
  },
  computed: {
    dialogTitle() {
      let title;
      if (this.dialogType == 1) {
        title = "添加快捷填充";
      } else {
        title = "编辑快捷填充";
      }
      return title;
    }
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTimeTimestamp = val[0] || "";
        this.searchForm.endTimeTimestamp = val[1] || "";
      }
    },
    // 监听一级分类选择更改
    "searchForm.firstServerCode": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getSecondType();
        } else {
          // 当一级分类选择为空时重置数据
          this.secondTypeList = [];
          this.searchForm.firstServerCode = "";
          this.searchForm.secondServerCode = "";
        }
      }
    },
    //
    "addFillForm.firstServerCode": {
      immediate: true,
      handler(val) {
        if (val !== "") {
          this.getSecondType("dialog");
        } else {
          // 当一级分类选择为空时重置数据
          this.dialogSecondTypeList = [];
          this.addFillForm.firstServerCode = "";
          this.addFillForm.secondServerCode = "";
        }
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //初始化数据
    async initData() {
      try {
        let promiseList = [getFirstType({ whether: 0 })];
        let [firstRes] = await Promise.all(promiseList);
        this.firstTypeList = this.getFreezeResponse(firstRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //获取一级服务类型
    async getFirstType() {
      try {
        let res = await getFirstType();
        this.firstTypeList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //获取二级服务类型
    async getSecondType(type) {
      try {
        let firstServerCode = type
          ? this.addFillForm.firstServerCode
          : this.searchForm.firstServerCode;
        let res = await getSecondType({
          firstServerCode
        });
        if (type == "dialog") {
          this.dialogSecondTypeList = _.cloneDeep(res.data);
        } else {
          this.secondTypeList = _.cloneDeep(res.data);
        }
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.fillId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //批量删除
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteRecord();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除请求
    async deleteRecord() {
      try {
        this.loading = true;
        let res = await deleteFill({ ids: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.dialogType = 2;
        this.addDioalog = true;
        this.getDetail(row.fillId);
      }
    },
    //获取详情
    async getDetail(id) {
      try {
        this.addDialogLoading = true;
        let res = await getFillDetail({ id });
        if (res.code == "OK") {
          this.detailInfo = res.data;
          this.addFillForm = _.cloneDeep(res.data);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addDialogLoading = false;
      }
    },
    /*****新增*********/
    //点击新增按钮
    handleAdd() {
      this.dialogType = 1;
      this.addDioalog = true;
    },
    //确定新增服务类型
    handleConfirm() {
      this.$refs.addFillForm.validate(valid => {
        if (valid) {
          if (this.dialogType == 1) {
            this.addReq();
          } else {
            this.updateReq();
          }
        }
      });
    },
    //添加填充信息请求
    async addReq() {
      try {
        this.addDialogLoading = true;
        let firstServer = this.firstTypeList.filter(item => {
          return item.codeId == this.addFillForm.firstServerCode;
        });
        let res = await createFill({
          ...this.addFillForm,
          firstServer: firstServer[0].codeName
        });
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addDialogLoading = false;
      }
    },
    //修改填充信息请求
    async updateReq() {
      try {
        this.addDialogLoading = true;
        let res = await updateFill(this.addFillForm);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.queryList();
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addDialogLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addFillForm = {
        fillContent: "", //自动填充内容
        firstServerCode: "", //一级服务类型
        secondServerCode: "" //二级服务类型
      };
      this.$refs.addFillForm.resetFields();
      this.addDioalog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .add-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-form {
      .el-input {
        width: 340px;
      }
      .el-textarea {
        width: 340px;
      }
    }
  }
}
</style>
